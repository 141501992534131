<script>
  import { onMount } from "svelte";

  let scrollY;
  onMount(() => {
    scrollY = 0;
  });
</script>

<svelte:window bind:scrollY />
