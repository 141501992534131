<script>
  import { Router, Route } from "svelte-routing";
  import Photos from "./Photos.svelte";
  import Welcome from "./Welcome.svelte";
  import Faq from "./Faq.svelte";
  import Nav from "./Nav.svelte";

  export let url = "";
</script>

<div class="container">
  <Photos />
  <main>
    <Router {url}>
      <Route path="/" component={Welcome} />
      <Route path="faq" component={Faq} />
      <Nav />
    </Router>
  </main>
</div>

<style>
  .container {
    background: #fff;
    padding: 30px;
    max-width: 600px;
    margin: 0 auto;
    -webkit-box-shadow: 2px 5px 16px 0px #1d1d1d;
    box-shadow: 2px 5px 16px 0px #1d1d1d;
    border-radius: 6px;
  }
</style>
